import React, { useEffect, useState } from "react";
import Draggable from 'react-draggable';

import"../css/chat.css";

const Chat = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const fetchMessages = async () => {
            const response = await fetch('/api/messages');
            const data = await response.json();
            console.log(data);
            return data;
        }

        fetchMessages().then(data => setMessages(data))


        return
    }, [])
    console.log(messages,'MESSAGES')
    const displayMessages = messages.map(message => {
        return(
            <div>
                <div style={{display:'flex', gap:'5px'}}>
                    <div style={{borderRadius:'100%',width:"20px", height:"20px", backgroundColor:"black"}}></div>
                    <p>Anonymous</p>
                </div>
                <p style={{color:'black'}}>{message.content}</p>
            </div>
        );
    })
    return (
        <Draggable>
            <div className="chat-outer-parent">
                    <div className="messages-parent">
                        {displayMessages}
                    </div>

                    <div className="message-input-parent">
                        <form>
                            <input type="textarea" placeholder="Type your message..." style={{ width: '90%', padding: '10px', borderRadius: '5px', border: '1px solid rgba(255, 255, 255, 0.5)' }} />
                        </form>
                    </div>
            </div>
        </Draggable>
    );
}

export default Chat;