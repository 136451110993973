import React, { useRef, useEffect } from 'react';

const rippleSettings = {
    maxSize: 100,
    animationSpeed: 5,
    strokeColor: [148, 217, 255],
};

const canvasSettings = {
    blur: 8,
    ratio: 1,
};

function Coords(x, y) {
    this.x = x || null;
    this.y = y || null;
}

const Ripple = function Ripple(x, y, circleSize, ctx) {
    this.position = new Coords(x, y);
    this.circleSize = circleSize;
    this.maxSize = rippleSettings.maxSize;
    this.opacity = 1;
    this.ctx = ctx;
    this.strokeColor = `rgba(${Math.floor(rippleSettings.strokeColor[0])},
    ${Math.floor(rippleSettings.strokeColor[1])},
    ${Math.floor(rippleSettings.strokeColor[2])},
    ${this.opacity})`;

    this.animationSpeed = rippleSettings.animationSpeed;
    this.opacityStep = (this.animationSpeed / (this.maxSize - circleSize)) / 2;
};

Ripple.prototype = {
    update: function update() {
        this.circleSize = this.circleSize + this.animationSpeed;
        this.opacity = this.opacity - this.opacityStep;
        this.strokeColor = `rgba(${Math.floor(rippleSettings.strokeColor[0])},
      ${Math.floor(rippleSettings.strokeColor[1])},
      ${Math.floor(rippleSettings.strokeColor[2])},
      ${this.opacity})`;
    },
    draw: function draw() {
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.strokeColor;
        this.ctx.arc(this.position.x, this.position.y, this.circleSize, 0,
            2 * Math.PI);
        this.ctx.stroke();
    },
};

const RippleCanvas = () => {
    const canvasRef = useRef(null);
    const ripples = useRef([]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const height = document.body.clientHeight;
        const width = document.body.clientWidth;

        canvas.style.filter = `blur(${canvasSettings.blur}px)`;

        canvas.width = width * canvasSettings.ratio;
        canvas.height = height * canvasSettings.ratio;

        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;

        let animationFrame;

        const canvasTouchStart = (e) => {
            e.preventDefault(); // Prevent default touch behavior
            const x = e.touches[0].clientX * canvasSettings.ratio;
            const y = e.touches[0].clientY * canvasSettings.ratio;
            ripples.current.unshift(new Ripple(x, y, 2, ctx));
        };

        const canvasTouchMove = (e) => {
            e.preventDefault(); // Prevent default touch behavior
            const x = e.touches[0].clientX * canvasSettings.ratio;
            const y = e.touches[0].clientY * canvasSettings.ratio;
            ripples.current.unshift(new Ripple(x, y, 2, ctx));
        };

        const canvasMouseOver = (e) => {
            const x = e.clientX * canvasSettings.ratio;
            const y = e.clientY * canvasSettings.ratio;
            ripples.current.unshift(new Ripple(x, y, 2, ctx));
        };

        const animation = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const length = ripples.current.length;
            for (let i = length - 1; i >= 0; i -= 1) {
                const r = ripples.current[i];

                r.update();
                r.draw();

                if (r.opacity <= 0) {
                    ripples.current[i] = null;
                    delete ripples.current[i];
                    ripples.current.pop();
                }
            }
            animationFrame = window.requestAnimationFrame(animation);
        };

        animation();
        canvas.addEventListener('touchstart', canvasTouchStart);
        canvas.addEventListener('touchmove', canvasTouchMove);
        canvas.addEventListener('mousemove', canvasMouseOver);

        return () => {
            window.cancelAnimationFrame(animationFrame);
            canvas.removeEventListener('touchstart', canvasTouchStart);
            canvas.removeEventListener('touchmove', canvasTouchMove);
            canvas.removeEventListener('mousemove', canvasMouseOver);
        };
    }, []);

    return <canvas ref={canvasRef} id="canvas" />;
};

export default RippleCanvas;