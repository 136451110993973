import { useRef, useState, useEffect } from 'react'
import '../css/home.css'
import RippleCanvas from '../utils/waterEffect';

import Chat from '../components/Chat';


export default function Home() {
    const audioRef = useRef();

    const [text, setText] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [showChat, setShowChat] = useState(false);

    useEffect(() => {
        if (!isPlaying) {
            audioRef.current.play();
            setIsPlaying(true);
        }

    }, [])

    const clicked = () => {
        if (!isPlaying) {
            audioRef.current.play();
            setIsPlaying(true);
        }
        return;
    }

    const submitText = (e) => {
        e.preventDefault();
        setShowChat(true)
    }

    return (
        <div className='home-page-container' onClick={clicked}>

            <div className="ripple-container">
                <RippleCanvas />
            </div>

            <audio ref={audioRef} src='https://d137d42ac0f4ae-songs.s3.amazonaws.com/Phantas-Ma-Goria.mp3'></audio>
            <div>
                <div className='title-container'>
                    <h1 className='heading'>MÜÜDI</h1>


                    {/* <div className='center-container email-container'>
                        <form className='center-container email-container' onSubmit={(e) => submitText(e)}>
                            <input value={text} onChange={(e) => setText(e.target.value)} className='email-input' type='text' placeholder='youremail@gmail.com' required />
                            <button className='submit-button' type='submit'>Submit</button>
                        </form>
                    </div> */}
                </div>

            </div>

            <div>
                {showChat ? (<Chat />) : ""}

            </div>


        </div>
    );
}
