import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/splash.css';
import GlobeSketch from '../utils/GlobeSketch';
import gif from '../assets/world.gif';
import RippleCanvas from '../utils/waterEffect';

export default function Splash() {
    const navigate = useNavigate();
    const auraRef = useRef();;


    const EnterSite = () => {
        navigate('/home');
    };

    useEffect(() => {

    }, [])

    return (
        <div className='parent-container-splash' onClick={() => EnterSite()}>
            <div>
                <RippleCanvas />
            </div>

            {/* <div className='splash-title-container'>
                <h2 className='click-here'>MÜÜDI</h2>
            </div> */}

            <div className='splash-title-child-container'>
                <p className='click-here-child'>Click to Enter site</p>
            </div>
            <script src='../utils/waterEffect.js' />
        </div>
    );
}
