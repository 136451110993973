import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Home from './routes/home';
import React from 'react';
import Splash from './routes/splash';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Splash />
  },
  {
    path: '/home',
    element: <Home />
  },
]);

export default function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
